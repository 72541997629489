import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default function createStore() {
	return new Vuex.Store({
		state: {
			host: '',
			// deviceType: 'pc',
			deviceType: 'mobile',
			showDebug: false,
			ads: {
				home_1: {
					class: 'adsbygoogle',
					style: 'display:block',
					'data-ad-client': 'ca-pub-7',
					'data-ad-slot': '1629776512',
					'data-ad-format': 'auto',
					'data-full-width-responsive': 'true',
				},

				home_recommand: [
					{
						class: 'adsbygoogle',
						style: 'display:block',
						'data-ad-client': 'ca-pub-7',
						'data-ad-slot': '9333840865',
						'data-ad-format': 'auto',
						'data-full-width-responsive': 'true',
					},
					{
						class: 'adsbygoogle',
						style: 'display:block',
						'data-ad-client': 'ca-pub-7',
						'data-ad-slot': '4177717101',
						'data-ad-format': 'auto',
						'data-full-width-responsive': 'true',
					},
				],
				cover_1: {
					class: 'adsbygoogle',
					style: 'display:block',
					'data-ad-client': 'ca-pub-7',
					'data-ad-slot': '2864635434',
					'data-ad-format': 'auto',
					'data-full-width-responsive': 'true',
				},
				content_1: {
					class: 'adsbygoogle',
					style: 'display:block',
					'data-ad-client': 'ca-pub-7',
					'data-ad-slot': '5286784646',
					'data-ad-format': 'auto',
					'data-full-width-responsive': 'true',
				},
				content_2: {
					class: 'adsbygoogle',
					style: 'display:block',
					'data-ad-client': 'ca-pub-7',
					'data-ad-slot': '9316694848',
					'data-ad-format': 'auto',
					'data-full-width-responsive': 'true',
				},
			},
			adPosConfig: {
				homeRecommand: [3, 12],
				homeRecommandPC: [5, 15],
			},

			adsensConfig: {},
			domainConfig: null,
		},
		mutations: {
			setHost(state, host) {
				state.host = host
			},
			setTitle(state, title) {
				state.title = title
			},
			setDomain(state, domainConfig) {
				state.domainConfig = domainConfig
			},
			setDeviceType(state, deviceType) {
				state.deviceType = deviceType
			},
			setDebug(state, showDebug) {
				state.showDebug = showDebug
			},
			setAdsense(state, adsenseConfig) {
				state.adsensConfig = adsenseConfig
			},
		},
		getters: {
			showDebug: (state) => state.showDebug,
			deviceType: (state) => state.deviceType,
			host: (state) => state.host,
			adsensConfig: (state) => state.adsensConfig,
			adPosConfig: (state) => state.adPosConfig,
			domainConfig: (state) => state.domainConfig,
		},
	})
}
